.customModal {
  .ant-modal-header .ant-modal-title {
    font-size: 28px;
    font-weight: bold;
  }
  &.ant-modal .ant-modal-content {
    padding: px_rem(69) px_rem(57);
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-modal-body > form {
    > .ant-form-item {
      &:last-child {
        margin-bottom: 0;
        margin-top: 60px;
      }
    }
  }
}

.confirmModal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .ant-modal-content {
    width: 509px;
    height: 334px;
    padding: 69px 62px;
    .ant-modal-header {
      .ant-modal-title {
        padding-bottom: 36px;
        p {
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #272a2d;
        }
      }
    }
    .ant-modal-body {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #272a2d;
      padding-bottom: 24px;
    }
    .ant-modal-footer {
      button {
        width: 94px;
        height: 40px;
        border-radius: 100px;
        font-size: 16px;
        &.ant-btn-default {
          background-color: #c9d4d9;
          color: #fff;
        }
      }
    }
  }
}
