@keyframes scaleBig {
  0% {
    transform: scale(0.6, 0.6);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.buttons {
  .send-sms-button {
    display: inline-block;
    width: auto;
    width: 140px;
    height: 56px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    background-color: #1169f7;
    border-radius: 100px;
    :hover {
      opacity: 0.8;
    }
    &.countdown-active {
      background-color: #c9d4d9;
    }
  }
}

.box-mobile {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #272a2d;
  strong {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #272a2d;
    display: block;
    padding-top: 8px;
  }
}
