@import '@/scss/abstracts/variables.scss';
@import '@/scss/abstracts/functions.scss';
@import '@/scss/abstracts/mixins.scss';
@import '@/scss/components/tabs.scss';
@import '@/scss/components/modal.scss';

.main {
  padding-top: px_rem(23);
  width: 767px;
  margin: 0 auto;
  > p {
    color: #272a2d;
    font-size: px_rem(24);
    font-weight: bold;
    padding-bottom: px_rem(64);
    padding-top: px_rem(64);
  }
  &__detail {
    width: 100%;
    display: grid;
    height: auto;
    grid-template-columns: px_rem(150) 2fr;
    column-gap: px_rem(50);
    justify-content: center;

    &__profileImage {
      position: relative;
      justify-self: start;
      color: black;
      border-radius: 100%;
      margin-bottom: px_rem(56);
      width: 100px;
      height: 100px;
      cursor: pointer;
      &__edit {
        width: 100%;
        text-align: center;
        margin: 0 !important;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        color: #9cabb3;
        font-size: px_rem(14);
      }
      img {
        border-radius: 100%;
        width: 64px;
        height: 64px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__bannerImage {
      position: relative;
      justify-self: start;
      color: black;
      margin-bottom: px_rem(20);
      width: 400px;
      height: 145px;
      cursor: pointer;

      &__edit {
        width: 100%;
        text-align: left;
        margin: 0 !important;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        color: #9cabb3;
        font-size: px_rem(14);
      }
      img {
        width: 100%;
        height: 114px !important;
      }
    }
    &__profileName {
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: center;
    }
    &__socialMedia {
      width: 100%;
    }
    &__title {
      display: flex;
      text-align: left;
      margin-bottom: px_rem(56);
      &.intro {
        margin-bottom: px_rem(0);
      }
      &.align-center {
        align-items: center;
        height: auto;
      }
    }

    &__value {
      display: flex;
      flex-direction: row;
      column-gap: px_rem(20);
      align-items: baseline;
      min-width: 330px;
      &.id-detail {
        height: 110px;
        position: relative;
      }
      form {
        width: 330px;
        height: 76px;
      }
      > p {
        overflow: hidden;
        max-width: 500px;
        text-overflow: ellipsis;
        width: 330px;
        margin-bottom: 10px;
        text-align: justify;
      }
      &__input {
        height: fit-content;
        border-radius: 100px;
        border: solid 1px #1169f7;
        max-width: 254px;
        // height: 48px;
        // &.errorClearIcon {
        //   > span {
        //     > span {
        //       color: red !important;
        //     }
        //   }
        // }
      }
      &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        .edit-button {
          cursor: pointer;
          color: #1169f7;
          min-width: 40px;
        }
      }
    }

    &__telegramLinks {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      a {
        text-decoration: none;
      }
      .toggle-button {
        background: none;
        border: none;
        color: #546c79;
        cursor: pointer;
        padding: 0;
        display: flex;
        align-items: center;
      }
    }
    &__telegramLink {
      margin-bottom: 24px;
    }
  }
}
.errorMessage {
  color: #ff4545;
  font-size: 14px;
  top: 6px;
  position: relative;
  // padding-left: 15px;
  // left: 10px;
}

// .errorMessage::before {
//   content: '•';
//   position: absolute;
//   left: 0;
//   top: -3px;
//   color: #ff4d4f;
//   font-size: 16px;
//   line-height: 1.5;
// }

.successMessage {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #546c79;
}

.pendingMessage {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #546c79;
}

.id-link {
  text-decoration: none;
  color: #1169f7 !important;
}
