.phoneInput {
  display: flex;
  text-align: center;
  input {
    width: 20%;
    margin: 0;
    height: 56px;
    padding-left: 10px;
    outline: none;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 6px;
    box-sizing: border-box;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

    &::placeholder {
      font-size: 16px;
      color: rgb(217, 217, 217);
    }
  }
  :global(.ant-input-group .ant-input-group-addon) {
    border-radius: 100px;
  }
}
